import React from "react";
import Button from "../../components/UI/Buttons/Button/Button";
import TextInput from "../../components/UI/Inputs/TextInput/TextInput";
import Registration from "../../images/svg/registration.svg";

import { useState } from "react";

const RegistrationScreen = ({
  setScreen,
}: {
  setScreen: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="w-full h-43vh">
        <img
          src={Registration}
          alt="Картинка регистрации"
          className="w-full "
        />
      </div>
      <div className="flex flex-col w-full text-left uppercase px-6 pt-[29px] pb-0 ">
        <span className="text-[56px] mb-[40px]">Регистрация</span>
        <div className="flex flex-col gap-3 mb-8">
          <TextInput
            placehoder="EMAIL"
            value={email}
            onChange={setEmail}
            icon={<span className="uppercase w-[150px]">Отправить код</span>}
          />
          <TextInput
            placehoder="ВВЕДИТЕ КОД ИЗ ПИСЬМА"
            value={code}
            onChange={setCode}
          />
        </div>

        <Button onClick={() => setScreen("pin-code")}>Продолжить</Button>
      </div>
    </div>
  );
};

export default RegistrationScreen;
