"use client";
import clsx from "clsx";
import type { FC } from "react";

const Spinner: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={clsx(
        "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]",
        className
      )}
      role="status"
    />
  );
};

export default Spinner;
