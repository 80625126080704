import clsx from "clsx";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  src: string;
}

const Icon: FC<IProps> = ({ src, className, ...rest }) => {
  return (
    <div
      className={clsx(className, "block")}
      style={{
        maskImage: `url(${src})`,
        maskRepeat: "no-repeat",
        WebkitMaskImage: `url(${src})`,
        WebkitMaskSize: "contain",
        maskSize: "contain",
        background: "currentColor",
      }}
      {...rest}
    />
  );
};

export default Icon;
