import React, { FC } from "react";

import ArrowDelete from "../../images/svg/arrowDelete.svg";

import KeyBoardButton from "./components/KeyBoardButton";

interface IProps {
  onDelete: () => void;
  onAdd: (value: string) => void;
}

const KeyboardList: FC<IProps> = ({ onAdd, onDelete }) => {
  return (
    <section className="px-6 grid grid-cols-3 w-full gap-4 place-items-center max-w-[400px]">
      {[...new Array(9)].map((_, index) => (
        <KeyBoardButton
          key={index}
          label={index + 1}
          onClick={() => onAdd((index + 1).toString())}
        />
      ))}
      <div className="w-[88px] h-[88px]" />
      <KeyBoardButton label={0} onClick={() => onAdd("0")} />
      <KeyBoardButton
        label={
          <img src={ArrowDelete} width={44} height={44} alt="Кнопка стереть" />
        }
        onClick={onDelete}
      />
    </section>
  );
};

export default KeyboardList;
