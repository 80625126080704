"use client";
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from "react";
import clsx from "clsx";
import Icon from "../../../UI/Icon/Icon";
import Spinner from "../../../UI/Spinner/Spinner";

interface IProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: string;
  isLoading?: boolean;
}

const Button: FC<React.PropsWithChildren<IProps>> = ({
  children,
  onClick,
  className,
  disabled,
  icon,
  isLoading,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        className,
        "w-full px-4 flex py-[18px] items-center gap-2 text-[24px] text-black bg-[#9EE449] rounded-[10px] justify-center button-shadow transition-opacity active:opacity-20",
        {
          "opacity-50 pointer-events-none": disabled,
        }
      )}
      onClick={onClick}
    >
      {icon && <Icon src={icon} className="h-5 w-5" />}
      {isLoading ? <Spinner /> : children}
    </button>
  );
};

export default Button;
