import KeyboardList from "../../components/KeyboardList";

import { useCallback, useEffect, useState } from "react";

const PinCodeScreen = () => {
  const [code, setCode] = useState<string>("****");

  useEffect(() => {
    if (code.indexOf("*") === -1) {
      setTimeout(() => {
        alert("Вы ввели код!");
      }, 100);
    }
  }, [code]);

  const handleDelete = useCallback(() => {
    setCode((prev) => {
      if (prev === "****") return prev;

      const lastDigitIndex = prev.search(/\d(?=\*|$)/);

      if (lastDigitIndex === -1) {
        return prev.slice(0, prev.length - 1) + "*";
      }

      return (
        prev.slice(0, lastDigitIndex) + "*" + prev.slice(lastDigitIndex + 1)
      );
    });
  }, [setCode]);

  const handleAddNumber = useCallback(
    (number: string) => {
      {
        setCode((prev) => {
          const index = prev.indexOf("*");
          if (index !== -1) {
            return (
              prev.substring(0, index) + number + prev.substring(index + 1)
            );
          }
          return prev;
        });
      }
    },
    [setCode]
  );

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <section className="flex flex-col items-center w-full bg-gradient-to-b from-[#9EE449] to-[#232323]  pt-[57px]">
        <span className="text-[56px]">Введите Пин-код</span>
        <span className="text-[150px]">{code}</span>
      </section>
      <KeyboardList onDelete={handleDelete} onAdd={handleAddNumber} />
    </div>
  );
};

export default PinCodeScreen;
