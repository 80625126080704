import React, { useState } from "react";

import "./App.css";
import WelcomeScreen from "./screens/WelcomeScreen/WelcomeScreen";
import RegistrationScreen from "./screens/RegistrationScreen/RegistrationScreen";
import PinCodeScreen from "./screens/PinCodeScreen/PinCodeScreen";

function App() {
  const [screen, setScreen] = useState("welcome");

  const appRender = () => {
    switch (screen) {
      case "welcome":
        return <WelcomeScreen setScreen={setScreen} />;
      case "registration":
        return <RegistrationScreen setScreen={setScreen} />;
      case "pin-code":
        return <PinCodeScreen />;
      default:
        return <WelcomeScreen setScreen={setScreen} />;
    }
  };
  return appRender();
}

export default App;
