"use client";
import React, { FC, ReactNode } from "react";

interface IProps {
  label: string | number | ReactNode;
  onClick?: () => void;
}

const KeyBoardButton: FC<IProps> = ({ onClick, label }) => {
  return (
    <div
      className="w-[88px] h-[88px] bg-[#3F3F3F] text-white rounded-lg text-[48px] flex items-center justify-center cursor-pointer active:border active:border-[#9EE449] active:shadow-[0px_-8px_17px_-4px_#9EE44999_inset] active:bg-[#262626]"
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default KeyBoardButton;
